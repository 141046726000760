.eventManagement-form-box {
  background-color: #ffffff;
  height: auto !important;
}
.eventManagement-form-box .formContentBox {
  padding: 12px;
}
.eventManagement-form-box .formMain {
  width: 100%;
  overflow: auto;
}
.eventManagement-form-box .step-box {
  background-color: #ffffff;
  width: 125px;
}
.eventManagement-form-box .formTopic {
  width: 100%;
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid rgb(187, 187, 187);
  font-weight: 600 !important;
  font-size: 18px;
}
.eventManagement-form-box .shuttleBackBox {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 60px;
}
.eventManagement-form-box .shuttleBackBox .el-tabs {
  width: 100%;
}
.eventManagement-form-box .shuttleBackBox .table-box {
  width: calc(100% - 280px);
}
.eventManagement-form-box .lossIndirect {
  display: inline-block;
  color: #909399;
  vertical-align: middle;
  position: relative;
  border-radius: 4px;
  padding: 0 10px;
  white-space: nowrap;
}
.eventManagement-form-box .shutleBack {
  width: 33%;
  background: #ffffff;
  border: 1px solid #ebeef5;
  border-radius: 4px;
}
.eventManagement-form-box .leftRightBtn {
  margin: 211px 12px;
}
.eventManagement-form-box .shutleTitle {
  width: 100%;
  background-color: #f5f7fa;
  height: 40px;
  line-height: 40px;
  text-indent: 12px;
  border-bottom: 1px solid #ebeef5;
  font-size: 16px;
}
.eventManagement-form-box .shutContent {
  padding: 6px;
  height: 400px;
  overflow: auto;
}
.eventManagement-form-box .deptBox {
  line-height: 40px;
  color: #333333;
  border-bottom: 1px solid #ebeef5;
  font-size: 13px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.eventManagement-form-box .unitBox {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: #f5f7fa;
  border-bottom: 1px solid #ebeef5;
}
.eventManagement-form-box .unitTitle {
  height: 40px;
  line-height: 40px;
  padding: 0 12px;
  font-size: 16px;
  cursor: pointer;
  border-right: 1px solid #ebeef5;
}
.eventManagement-form-box .content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.eventManagement-form-box .content .img-content {
  width: 188px;
  height: 100px;
  padding: 3px;
  position: relative;
  border: 1px solid #ccc;
  border-radius: 3px;
  margin-right: 12px;
}
.eventManagement-form-box .content .img-content .delete-icon {
  position: absolute;
  top: -6px;
  right: -6px;
  font-size: 16px;
  color: red;
}
.eventManagement-form-box .content .img-content img {
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  height: 100%;
}